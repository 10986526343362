import React, { Component } from "react";
import "./footer.css";
import img from "../../../images/ani2.gif";
import Fade from 'react-reveal/Fade';

class Footer extends Component {
  render() {
    return (
      <Fade bottom>
        <footer>
          <div className="container1">
            <div className="footer_inner">
              <div className="text_wrapper">
                <p style={{ marginBottom: `20px` }}>
                  <img style={{ maxWidth: `100%`, height: `auto`, width: `130px` }} className="custom-logo" src={img} alt="c" />
                  <br />
                  <a style={{ fontSize: "40px",color: '#fdee73' }} href="https://ux-capital.com">{this.props.pageName}</a>
                </p>
                <div
                  style={{
                    margin: "2px 0 8px",
                    // color: "#708e9f",
                  }}
                >
                  <span className="footer-icons">
                    <i className="fas fa-envelope"></i>{" "}
                    <div className="footer-icons-container">
                      <span style={{ fontWeight: "bold" }}>E-MAIL</span>
                      <a href="mailto:sales@capital.asia" style={{ display: "block", textAlign: "left", marginLeft: "30px" }} className="footer-icon"> sales@capital.asia</a>
                    </div>
                  </span>
                  {/* <span className="footer-icons">
                    <i className="fas fa-compass"></i>{" "}
                    <div className="footer-icons-container">
                      <span style={{ fontWeight: "bold" }}>ADDRESS</span>
                      <span className="footer-icon">&ensp;</span>
                    </div>
                  </span> */}
                  <span className="footer-icons">
                    <i className="fas fa-phone"></i>{" "}
                    <div className="footer-icons-container">
                      <span style={{ fontWeight: "bold" }}>PHONE</span>
                      <span className="footer-icon">9423-6069</span>
                    </div>
                  </span>
                </div>
              </div>

            </div>
          </div>
        </footer>
      </Fade>
    );
  }
}

export default Footer;