import React, { Component } from "react";
import "../home1/style.css";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header/index";
import logo from "../../images/ani1.gif";
// import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';

class Page1 extends Component {
    render() {
        return (
            <div>
                <Header />
                <div className="home1">
                    <section className="second">
                        <div className="center">
                            <Flip bottom delay={500}>
                                <div style={{ margin: "0 auto", textAlign: "center" }}>
                                    <img src={logo} className="App-logo" alt="r" />
                                </div>
                            </Flip>
                            <Flip right>
                                <h2 className="second__heading second__heading__home">Introduction of UX Group</h2>
                            </Flip>
                        </div>
                        {/* <h2 className="second__para-heading">HEADING</h2> */}
                        <p className="second__para" style={{ textAlign: "justify", width: "100%", padding: "0 20px" }}>
                            UX Group is a diverse business group that reflects our outreach to help our regional and global customers and partners to meet their business requirements in many different fields.<br /><br />
                        Our integrated internal structure enables us to leverage synergies across the entire group allowing our expertise to address all the challenges in this everchanging business climate.<br /><br />
                        In keeping to our trademark of client-driven innovation, we continue to pursue new growth opportunities regionally and internationally so that we can grow strength to strength together with our business partners to achieve our business objective and aim collectively.          </p>
                    </section>
                </div>
                <Footer pageName="UX Group" />
            </div>
        );
    }
}

export default Page1;
