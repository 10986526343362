import React from 'react';
import './App.css';
import Home from './pages/home1';
import Categories from './pages/categories';
import Contact from './pages/contact';
import Offshore from './pages/offshore';
import capital from './pages/capital';
import {BrowserRouter, Route} from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
    <Route exact path='/' component={Home} />
    <Route exact path='/categories' component={Categories} />
    <Route exact path='/offshore' component={Offshore} />
    <Route exact path='/capital' component={capital} />
    <Route exact path='/contact' component={Contact} />
   
    </div>
    </BrowserRouter>
  );
}

export default App;
