import React, { Component } from "react";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header/index";
import marine1 from "../../images/marine1.jpeg";
import marine2 from "../../images/marine2.jpeg";
import marine3 from "../../images/marine3.jpeg";
import oil1 from "../../images/oil1.jpeg";
import oil2 from "../../images/oil2.jpeg";
import oil3 from "../../images/oil3.jpeg";
import "../offshore/style.css";
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';

class Offshore extends Component {
  render() {
    return (
      <div className="offshore">
        <Header />
        <div className="offshore-body">
          <section className="second">
            <div className="center">
              <Bounce top>
                <h2 className="second__heading" >UX Offshore</h2>
              </Bounce>
            </div>
            <div className="row both-sections">
              <Fade left>
                <div className="col left-section">
                  <h2 className="second__para-heading">
                    OIL & GAS - Fuel supply & Fuel Brokerage
                </h2>
                  <p className="second__para">
                    UX Offshore comprises a commercial and technical team to conduct fuel bunkering supply for all our value customers worldwide.<br />
                    <span className="heading_of_para">IFO-380 cSt: </span>Intermediate fuel oil with a maximum viscosity of 380 centistokes. (Less than 3.5% Sulphur) <br />
                    <span className="heading_of_para">IFO-180 cSt:</span> Intermediate fuel oil with a maximum viscosity of 180 centistokes.(Less than 3.5% Sulphur)<br />
                    <span className="heading_of_para">Marine Diesel Oil (MDO):</span> Also called "Distillate Marine Diesel", MDO is widely used by medium and high speed marine diesel engines.<br />
                    <span className="heading_of_para">Marine Gas Oil (MGO): </span>A marine fuel that consist exclusively of distillates, MGO is used in smaller medium to high speed auxiliary units or auxiliary motors and ship's engines.<br />
                  </p>

                  {/* <a href="javascript:void(0);" className="second__btn">Learn more &rarr;</a> */}
                </div>
              </Fade>

              <Fade right>
                <div className="col right-section">

                  <div id="carousel-example" className="carousel slide" data-ride="carousel" data-interval="3000">
                    <ol className="carousel-indicators">
                      <li data-target="#carousel-example" data-slide-to="0" className="active"></li>
                      <li data-target="#carousel-example" data-slide-to="1"></li>
                      <li data-target="#carousel-example" data-slide-to="2"></li>
                    </ol>

                    <div className="carousel-inner">
                      <div className="item active">
                        <a href="#"><img src={oil1} width="1200px" /></a>
                      </div>
                      <div className="item">
                        <a href="#"><img src={oil2} width="1200px" /></a>
                      </div>
                      <div className="item">
                        <a href="#"><img src={oil3} width="1200px" /></a>
                      </div>
                    </div>
                  </div>

                </div>
              </Fade>
            </div>

          </section>
          <section className="second">
            {window.innerWidth >= 1000 ?
              <div className="row both-sections">

                <div className="col left-section">
                  <div className="second__photo-group">

                    <div id="carousel-example1" className="carousel slide" data-ride="carousel" data-interval="3000">
                      <ol className="carousel-indicators">
                        <li data-target="#carousel-example1" data-slide-to="0" className="active"></li>
                        <li data-target="#carousel-example1" data-slide-to="1"></li>
                        <li data-target="#carousel-example1" data-slide-to="2"></li>
                      </ol>

                      <div className="carousel-inner">
                        <div className="item active">
                          <a href="#"><img src={marine1} width="2000px" /></a>
                        </div>
                        <div className="item">
                          <a href="#"><img src={marine2} width="2000px" /></a>
                        </div>
                        <div className="item">
                          <a href="#"><img src={marine3} width="2000px" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col right-section" style={{ paddingLeft: "30px" }}>
                  <h2 className="second__para-heading">Marine Engineering Works</h2>
                  <p className="second__para">UX Offshore has a dedicated team to carry out following marine and offshore engineering works from our professional team to all our value marine and offshore customers locating in the local shipyards or offshore remote location.
               <ul>
                      <li>Heavy engineering works on metal fabrication or electrical and electronic service, repair or maintenance works.</li>
                      <li>Labor Supply for all marine or offshore works ( Electrical such as cable pulling, installation, commissioning etc)</li>
                      <li> Marine & Rigs Spare Parts & Equipments supply</li>
                      <li> Crew Training and certifications </li>
                      <li>Ship Chandling & Supply </li>
                      <li> General Marine and Offshore Works</li>
                    </ul>
                  </p>
                </div>
              </div>
              :
              <div className="row both-sections">

                <div className="col right-section" style={{ paddingLeft: "30px" }}>
                  <h2 className="second__para-heading">Marine Engineering Works</h2>
                  <p className="second__para">UX Offshore has a dedicated team to carry out following marine and offshore engineering works from our professional team to all our value marine and offshore customers locating in the local shipyards or offshore remote location.
               <ul>
                      <li>Heavy engineering works on metal fabrication or electrical and electronic service, repair or maintenance works.</li>
                      <li>Labor Supply for all marine or offshore works ( Electrical such as cable pulling, installation, commissioning etc)</li>
                      <li> Marine & Rigs Spare Parts & Equipments supply</li>
                      <li> Crew Training and certifications </li>
                      <li>Ship Chandling & Supply </li>
                      <li> General Marine and Offshore Works</li>
                    </ul>
                  </p>
                </div>


                <div className="col left-section">
                  <div className="second__photo-group">

                    <div id="carousel-example1" className="carousel slide" data-ride="carousel" data-interval="3000">
                      <ol className="carousel-indicators">
                        <li data-target="#carousel-example1" data-slide-to="0" className="active"></li>
                        <li data-target="#carousel-example1" data-slide-to="1"></li>
                        <li data-target="#carousel-example1" data-slide-to="2"></li>
                      </ol>

                      <div className="carousel-inner">
                        <div className="item active">
                          <a href="#"><img src={marine1} width="1200px" /></a>
                        </div>
                        <div className="item">
                          <a href="#"><img src={marine2} width="1200px" /></a>
                        </div>
                        <div className="item">
                          <a href="#"><img src={marine3} width="1200px" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            }
          </section>
        </div>
        <Footer pageName="UX Offshore" />
      </div>
    );
  }
}

export default Offshore;
