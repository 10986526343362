import React, { Component } from "react";
import "./header.css";
import img from "../../../images/1.png";
import { Link } from "react-router-dom";
import Flip from "react-reveal/Flip";
import ct from 'countries-and-timezones';
class Header extends Component {
  state = {
    open: false,
    newYork: "",
    tokyo: "",
    london: "",
    singapore: "",
    melbourne: "",
    beijing: "",
    southAfrica: "",
    dubai: "",
    rio: ""
  }
  componentDidMount = () => {
    console.log(ct)
    setInterval(() => {
      this.getCountryTimeZone("America/Los_Angeles", "newYork");
      this.getCountryTimeZone("Asia/Tokyo", "tokyo");
      this.getCountryTimeZone("Europe/London", "london");
      this.getCountryTimeZone("Asia/Dubai", "dubai");
      this.getCountryTimeZone("Asia/Shanghai", "beijing");
      this.getCountryTimeZone("Australia/Melbourne", "melbourne");
      this.getCountryTimeZone("Africa/Johannesburg", "southAfrica");
      this.getCountryTimeZone("Asia/Singapore", "singapore");
      this.getCountryTimeZone("America/Rio_Branco", "rio");
    }, 1000);
    let close = document.querySelector('#close')
    close.style.display = "none"
  }

  getCountryTimeZone = (country, countryName) => {
    const timezone = ct.getTimezone(country);
    const UTCTime = new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000).getTime();
    const countryOffset = timezone.dstOffset * 60 * 1000;
    const countryTime = new Date(UTCTime + countryOffset).toLocaleTimeString();
    this.setState({ [countryName]: countryTime });
  }

  changeToggle = (e) => {
    e.preventDefault();
    this.setState({ open: !this.state.open });
    let bars = document.querySelector('#bars')
    let close = document.querySelector('#close')


    if (this.state.open === false) {
      bars.style.display = "none"
      close.style.display = "block"
    } else {
      close.style.display = "none"
      bars.style.display = "block"
    }
  }
  render() {

    return (
      <div style={{ position: `relative` }}>
        <Flip top>
          <div className={`responsive_nav ${this.state.open ? "active_nav" : ""}`}>
            <ul className="mobile_nav">
              <li>
                <div id="site-logo" className="clr has-responsive-logo" style={{ marginBottom: "30px" }}>
                  <div id="site-logo-inner" className="clr">
                    <Link to="http://uxcapital.asia/" className="custom-logo-link">
                      <img src={img} className="custom-logo" alt="ux-capital.com" />
                    </Link>
                  </div>
                </div>
              </li>
              <li className="responsive_link">
                <Link to="/">
                  <span>Home</span>
                </Link>
              </li>
              <li className="responsive_link">
                <Link to="/categories">
                  <span>Mission</span>
                </Link>
              </li>
              <li className="responsive_link">
                <Link to="/offshore">
                  <span>UX Offshore</span>
                </Link>
              </li>
              <li className="responsive_link">
                <Link to="/capital">
                  <span>UX Capital</span>
                </Link>
              </li>
              <li className="responsive_link">
                <Link to="/contact">
                  <span>Contact</span>
                </Link>
              </li>
            </ul>
          </div>


          <header style={{ position: `relative` }}>
            <div style={{ position: "relative" }}>
              <div className="header_outer">
                <div className="header_inner">
                  <div id="site-logo" className="clr has-responsive-logo">
                    <div id="site-logo-inner" className="clr">
                      <Link to="/" className="custom-logo-link">
                        <img src={img} className="custom-logo" alt="ux-capital.com" />
                      </Link>
                      <Link to="/" className="responsive-logo-link">
                        <img src={img} className="responsive-logo" width="400px" alt="" />
                      </Link>
                    </div>
                  </div>
                  <div onClick={this.changeToggle}>
                    {/* <label className={`${this.state.open ? "close fas fa-times" : "bars fas fa-bars"}`}></label> */}
                    <i className="close fas fa-times" id="close"></i>
                    <i className="bars fas fa-bars" id="bars"></i>

                  </div>
                  <div className="nav_outer">
                    <div className="nav_inner">
                      <ul className="nav">
                        <li className="header_link">
                          <Link to="/">
                            <span>Home</span>
                          </Link>
                        </li>
                        <li className="header_link">
                          <Link to="/categories">
                            <span>Mission</span>
                          </Link>
                        </li>
                        <li className="header_link">
                          <Link to="/offshore">
                            <span>UX Offshore</span>
                          </Link>
                        </li>
                        <li className="header_link">
                          <Link to="/capital">
                            <span>UX Capital</span>
                          </Link>
                        </li>
                        <li className="header_link">
                          <Link to="/contact">
                            <span>Contact</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
              <marquee style={{ fontFamily: "Book Antiqua", color: "#fff",fontSize: "20px", position: "relative", bottom: "0px" }} bgcolor="#000" scrollamount="4">
                <span    style={{ display: `inline-block`, width: "300px" }}>New York: {this.state.newYork}</span>
                <span style={{ display: `inline-block`, width: "300px" }}>Dubai: {this.state.dubai}</span>
                <span style={{ display: `inline-block`, width: "300px" }}>Tokyo: {this.state.tokyo}</span>
                <span style={{ display: `inline-block`, width: "300px" }}>London: {this.state.london}</span>
                <span style={{ display: `inline-block`, width: "300px" }}>Beijing: {this.state.beijing}</span>
                <span style={{ display: `inline-block`, width: "300px" }}>Melbourne: {this.state.melbourne}</span>
                <span style={{ display: `inline-block`, width: "300px" }}>Singapore: {this.state.singapore}</span>
                <span style={{ display: `inline-block`, width: "300px" }}>Cape Town: {this.state.southAfrica}</span>
                <span style={{ display: `inline-block`, width: "300px" }}>Rio de Janeiro: {this.state.rio}</span>
              </marquee>
            </div>
          </header>
        </Flip>

      </div>


    );
  }
}

export default Header;