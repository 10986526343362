import React, { Component } from 'react';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header/index';
import '../contact/style.css';
import LightSpeed from 'react-reveal/LightSpeed';

class Contact extends Component {
    render() {
        return (
            <div>
                <Header />
                <div className="contact-body">
                    <section class="second">
                        <div class="center">
                        <LightSpeed left delay={500}>
                            <h2 className="second__heading" style={{margin: "40px auto 100px"}}>Contact</h2>
                            </LightSpeed>
                        </div>


                        <div class="wrapper">
                            <form action="#" method="post">
                                <div class="field">
                                    <input type="text" id="name" name="name" placeholder="What is Your name?" autofocus />
                                    <label for="name">Name</label>
                                </div>
                                <div class="field">
                                    <input type="text" id="email" name="email" placeholder="name@gmail.com" />
                                    <label for="email">E-Mail</label>
                                </div>
                                <div class="field">
                                    <input type="text" id="subject" name="subject" placeholder="Subject" autofocus />
                                    <label for="subject">Subject</label>
                                </div>
                                <div class="field">
                                    <textarea id="msg" rows="4" name="msg" placeholder="You message..."></textarea>
                                    <label for="msg">Message</label>
                                </div>
                                <div className="center">
                                    <input class="button" type="submit" value="Send" />
                                </div>
                            </form>

                        </div>
                    </section>
                </div>
                <Footer pageName="UX Group" />
            </div>
        );
    }
}

export default Contact;