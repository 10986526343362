import React, { Component } from 'react';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header/index';
import logo from '../../images/ani1.gif';
import './style.css';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

class Categories extends Component {
    render() {
        return (
            <div className="categories">
                <Header />
                <div className="second">
                    <Zoom>
                        <div style={{ margin: "0 auto", textAlign: "center" }}>
                            <img src={logo} className="App-logo" alt="" />
                            <br />
                            <p className="second__heading ">MISSION, VISION & VALUES</p>
                        </div>
                    </Zoom>
                    <Fade top delay={300}>
                            <div className="center" className="center-para">
                                <h2 className="second__para-heading">OUR PEOPLE & VALUES</h2>
                                <p class="second__para">
                                UX Group of Companies values each individuals and who we are and what we stand for. Our people help build enduring relationships based on mutual trust with our customers, partners, suppliers and communities locally and globally. 
                                </p>
                            </div>
                        </Fade>
                    <br/><br/>
                    <div className="categories-headings">
                        <Fade right delay={300}>
                            <div className="categories-offshore right-para">
                                <h2 className="second__para-heading">OUR MISSION</h2>
                                <p class="second__para">
                                UX Group of Companies uses our vast global experiences, market knowledge and logistic capabilities to balance supply and demand, optimise supply chains and service the needs of our customers in Singapore, Asia or around the world.
                                </p>
                            </div>
                        </Fade>
                        <br/><br/>
                        <Fade left delay={300}>
                            <div className="categories-offshore left-para">
                                <h2 className="second__para-heading">OUR VISION</h2>
                                <p class="second__para">
                                Our Vision is to drive a high-performance culture, leveraging our expertise at the heart of UX Group of Companies, to deliver value while being flexible and agile to the needs of our value customers worldwide.
                                </p>
                            </div>
                        </Fade>

                    </div>
                        
                </div>
                <Footer pageName="UX Group" />
            </div>
        );
    }
}

export default Categories;