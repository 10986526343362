import React, { Component } from 'react';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header/index';
import finance1 from '../../images/finance1.jpeg';
import finance2 from '../../images/finance2.jpeg';
import finance3 from '../../images/finance3.jpeg';
import trading1 from '../../images/trading1.jpeg';
import trading2 from '../../images/trading2.jpeg';
import trading3 from '../../images/trading3.jpeg';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';

import '../capital/style.css';


class Capital extends Component {
    render() {
        return (
            <div>
                <Header />
                <div className="capital-body">

                    <section class="second">
                        <div class="center">
                            <Bounce top>
                                {/* <div class="second__heading"> */}
                                <h2 className="second__heading">UX Capital</h2>
                                {/* </div> */}
                            </Bounce>
                        </div>
                        <div class="row both-sections">
                            <Fade right>
                                <div class="col left-section">
                                    <h2 class="second__para-heading">Finance & Leasing</h2>
                                    <p class="second__para">UX Capital provide a wide spectrum of equipment leasing and capital financing solutions, individually customised to suit our customers’ unique needs and requirements. Our philosophy and aim is to designed and help business owners improve cash flow flexibility, enhance financial budgeting and seize expansion opportunities.<br />
                        UX Capital firmly believes business owner should enjoy all the profit-generating benefits and convenience of having your own equipment without the headaches of asset-ownership and depreciation.<br />
                        With our Hire Purchase in house loan, you can now spread out your payment over time to reduce your upfront costs while you enjoy the use and ownership of the asset. We provide you an easy access to additional funds at competitive interest rates so that you can fully focus on growing your business with our partnership program.</p>
                                    {/* <a href="javascript:void(0);" class="second__btn">Learn more &rarr;</a> */}

                                </div>
                            </Fade>
                            <Fade left>
                                <div class="col right-section">
                                    <div class="second__photo-group">

                                        <div id="carousel-example1" class="carousel slide" data-ride="carousel" data-interval="3000">
                                            <ol class="carousel-indicators">
                                                <li data-target="#carousel-example1" data-slide-to="0" class="active"></li>
                                                <li data-target="#carousel-example1" data-slide-to="1"></li>
                                                <li data-target="#carousel-example1" data-slide-to="2"></li>
                                            </ol>

                                            <div class="carousel-inner">
                                                <div class="item active">
                                                    <a href="#"><img src={finance1} width="2000px" /></a>
                                                </div>
                                                <div class="item">
                                                    <a href="#"><img src={finance2} width="2000px" /></a>
                                                </div>
                                                <div class="item">
                                                    <a href="#"><img src={finance3} width="2000px" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </section>
                    <section class="second">
                        {window.innerWidth >= 1000 ?
                            <div class="row both-sections">
                                <div class="col right-section">
                                    <div class="second__photo-group">

                                        <div id="carousel-example1" class="carousel slide" data-ride="carousel" data-interval="3000">
                                            <ol class="carousel-indicators">
                                                <li data-target="#carousel-example1" data-slide-to="0" class="active"></li>
                                                <li data-target="#carousel-example1" data-slide-to="1"></li>
                                                <li data-target="#carousel-example1" data-slide-to="2"></li>
                                            </ol>

                                            <div class="carousel-inner">
                                                <div class="item active">
                                                    <a href="#"><img src={trading1} width="100%" /></a>
                                                </div>
                                                <div class="item">
                                                    <a href="#"><img src={trading2} width="100%" /></a>
                                                </div>
                                                <div class="item">
                                                    <a href="#"><img src={trading3} width="100%" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col left-section" style={{ paddingLeft: "30px" }}>
                                    <h2 class="second__para-heading">FMCG TRADING - WHOLESALE - IMPORT / EXPORT </h2>
                                    <p class="second__para"> UX Capital FMCG trading team provides competitive whole and retail import and export goods regionally and globally. We buy and sell FMCG goods from basic commodities like rice, sugar, salt to luxury products with the likes of skin care products, perfumes and household appliances. We also import and export vehicles and medical equipment and devices for our regional and global clients as per their requirements.</p>
                                </div>
                            </div>
                            :
                            <div class="row both-sections">

                                <div class="col left-section" style={{ paddingLeft: "30px" }}>
                                    <h2 class="second__para-heading">FMCG TRADING - WHOLESALE - IMPORT / EXPORT </h2>
                                    <p class="second__para"> UX Capital FMCG trading team provides competitive whole and retail import and export goods regionally and globally. We buy and sell FMCG goods from basic commodities like rice, sugar, salt to luxury products with the likes of skin care products, perfumes and household appliances. We also import and export vehicles and medical equipment and devices for our regional and global clients as per their requirements. </p>
                                </div>
                                <div class="col right-section">
                                    <div class="second__photo-group">

                                        <div id="carousel-example1" class="carousel slide" data-ride="carousel" data-interval="3000">
                                            <ol class="carousel-indicators">
                                                <li data-target="#carousel-example1" data-slide-to="0" class="active"></li>
                                                <li data-target="#carousel-example1" data-slide-to="1"></li>
                                                <li data-target="#carousel-example1" data-slide-to="2"></li>
                                            </ol>

                                            <div class="carousel-inner">
                                                <div class="item active">
                                                    <a href="#"><img src={trading1} width="100%" /></a>
                                                </div>
                                                <div class="item">
                                                    <a href="#"><img src={trading2} width="100%" /></a>
                                                </div>
                                                <div class="item">
                                                    <a href="#"><img src={trading3} width="100%" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    </section>
                </div>
                <Footer pageName="UX Capital" />
            </div>
        );
    }
}

export default Capital;